<template>
  <div class="Resume">
    <div class="top-title">
      <div class="top-text">简历列表</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
         <el-form-item label="姓名" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>

          <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
      
    </div>

    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStylejl" v-loading="loading">
        <el-table-column label="序号" width="70px">
           <template slot-scope="scope"> {{scope.$index+1}} </template>
          </el-table-column>
        <el-table-column prop="true_name" label="姓名"> </el-table-column>
        <el-table-column prop="bu_mobile" label="手机号"> </el-table-column>
        <el-table-column prop="edulevel_name" label="学历"> </el-table-column>

        <el-table-column label="操作" width="100" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"
              type="text"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      seeklist:fakelist.seeklist,

      // 假数据
      list:[],
    };
  },
  created() {
   
    this.getSong()
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'WorkerEntity',
        handleMode:'fetchallResume',
        query:{
         memberName:this.seeks.a,
        }, })
   
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },

    // 跳转详情页
    handleClick(row) {
      this.$router.push({ path: `/home/Resumepar/${row.id}` });
    },

    // 搜索栏
    seek(){
            this.currentPage = 1;
        this.getSong()
    },




    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
@import "../../../assets/css/liststyle.css";
</style>